<template>
  <svg viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.62143 16.1314C6.62143 12.8026 6.62143 11.1382 7.26926 9.86677C7.83911 8.74839 8.74839 7.83911 9.86677 7.26926C11.1382 6.62143 12.8026 6.62143 16.1314 6.62143H20.8864C24.2152 6.62143 25.8796 6.62143 27.1511 7.26926C28.2695 7.83911 29.1787 8.74839 29.7486 9.86677C30.3964 11.1382 30.3964 12.8026 30.3964 16.1314V17.49C30.3964 20.8188 30.3964 22.4832 29.7486 23.7547C29.1787 24.873 28.2695 25.7823 27.1511 26.3522C25.8796 27 24.2152 27 20.8864 27H12.0557C10.1535 27 9.20245 27 8.47591 26.6298C7.83683 26.3042 7.31725 25.7846 6.99162 25.1455C6.62143 24.419 6.62143 23.4679 6.62143 21.5657V16.1314ZM15.1125 16.8107C15.1125 15.8728 15.8728 15.1125 16.8107 15.1125C17.7486 15.1125 18.5089 15.8728 18.5089 16.8107V20.2071C18.5089 21.145 17.7486 21.9054 16.8107 21.9054C15.8728 21.9054 15.1125 21.145 15.1125 20.2071V16.8107ZM23.6036 15.1125C22.6657 15.1125 21.9054 15.8728 21.9054 16.8107V20.2071C21.9054 21.145 22.6657 21.9054 23.6036 21.9054C24.5415 21.9054 25.3018 21.145 25.3018 20.2071V16.8107C25.3018 15.8728 24.5415 15.1125 23.6036 15.1125Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5522 1.48882C12.6679 2.41955 12.0073 3.26788 11.0765 3.38362C10.5635 3.44743 10.1513 3.53423 9.78645 3.64792C6.85591 4.56111 4.56111 6.85591 3.64792 9.78645C3.53423 10.1513 3.44743 10.5635 3.38362 11.0765C3.26788 12.0073 2.41955 12.6679 1.48882 12.5522C0.558091 12.4365 -0.102585 11.5881 0.0131584 10.6574C0.0962845 9.98895 0.219312 9.37279 0.405278 8.776C1.65054 4.77981 4.77981 1.65054 8.776 0.405278C9.37279 0.219312 9.98895 0.0962845 10.6574 0.0131584C11.5881 -0.102585 12.4365 0.558091 12.5522 1.48882Z"
      fill="currentColor"
    />
  </svg>
</template>
